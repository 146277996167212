import { useDomain } from '../../../../context/domain'

import AssenLogo from '../../../../../svg/area/assen/logo.svg'
import BeezyLogo from '../../../../../svg/area/beezy/logo.svg'
import BuurtBoeket from '../../../../../svg/area/buurtboeket/logo.svg'
import DenBoschLogo from '../../../../../svg/area/denbosch/logo.svg'
import GeleenLogo from '../../../../../svg/area/geleen/logo.svg'
import GroningenLogo from '../../../../../svg/area/groningen/logo.svg'
import HilversumLogo from '../../../../../svg/area/hilversum/logo.svg'
import SteenwijkerlandLogo from '../../../../../svg/area/steenwijkerland/logo.svg'
import TerneuzenLogo from '../../../../../svg/area/terneuzen/logo.svg'
import ZeelandLogo from '../../../../../svg/area/zeeland/logo.svg'
import ZwolleLogo from '../../../../../svg/area/zwolle/logo.svg'
import Zupr from '../../../../../svg/logo.svg'

const logos = {
    nederland: Zupr, // Zupr Nederland
    groningen: GroningenLogo, // Groningen
    hilversum: HilversumLogo, // Hilversum
    denbosch: DenBoschLogo, // Den Bosch
    terneuzen: TerneuzenLogo, // Terneuzen
    zwolle: ZwolleLogo, // Zwolle
    geleen: GeleenLogo, // Geleen
    assen: AssenLogo, // Assen
    steenwijkerland: SteenwijkerlandLogo, // Shop in de kop
    zeeland: ZeelandLogo, // Warenhuis zeeland
    beezy: BeezyLogo, // Beezy
    bloemisten: BuurtBoeket,
}

const Logo = () => {
    const { slug } = useDomain()
    const Logo = logos[slug] || logos.nederland
    return <Logo />
}

export default Logo
